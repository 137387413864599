<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="defaultTuneList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Default Tunes</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Default Tune" icon="pi pi-plus" class="p-mr-2"
                                @click="addDefaultTunesDialogOPen" />
                            <!-- <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="filterlisting()"></Button> -->
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header=" Tune Alias name" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mbg5 ? data.mbg5 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Tune Type" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-if="data.mbg2 == 1"> MP3</span>
                                <span v-if="data.mbg2 == 2"> TTS</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Tune" headerStyle="width: 35%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize" v-if="data.mbg2 == 2">{{ data.mbg4 ? data.mbg4 : 'N/A' }}
                            </div>
                            <div v-if="data.mbg4 != null && data.mbg4 != '' && data.mbg2 == 1"><audio controls
                                    controlsList="download">
                                    <source
                                        :src="'https://storage.googleapis.com/' + this.imagestorageimgpath + '/default_tune/' + data.mbg4"
                                        type="audio/mpeg" />
                                </audio>
                            </div>
                            <div v-if="data.mbg4 == null">-</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editDefaultTunesDialogOPen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add Default Tunes dialog start here -->
    <Dialog v-model:visible="addDefaultTunesStatus" :style="{ width: '800px' }" header="Add Default Tune" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid" @keydown.enter="submitForm">
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label for="tuneCatagoryid">
                                Tune Catagory
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Dropdown id="tuneCatagoryid" v-model="add.tuneCatagory" :options="tuneCatagoryList"
                                optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                            <small class="p-invalid p-error" v-if="v$.add.tuneCatagory.$error">{{
                                v$.add.tuneCatagory.$errors[0].$message
                                }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="tunetypeid">
                                Tune Type
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Dropdown id="tunetypeid" v-model="add.tuneType" :options="tuneTypeList" optionLabel="label"
                                placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                            <small class="p-invalid p-error" v-if="v$.add.tuneType.$error">{{
                                v$.add.tuneType.$errors[0].$message
                                }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="aliasnameid">
                                Tune Alias name
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="aliasnameid" v-model.trim="add.aliasname"
                                required="true" maxlength="20" autofocus
                                :class="{ 'p-invalid': submitted && !add.aliasname }" />
                            <small class="p-invalid p-error" v-if="v$.add.aliasname.$error">{{
                                v$.add.aliasname.$errors[0].$message
                                }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6" v-if="add.tuneType.value == 2">
                            <label for="ttsTextid">
                                Tune Text
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="ttsTextid" v-model.trim="add.ttsText"
                                required="true" autofocus :class="{ 'p-invalid': submitted && !add.ttsText }" />
                            <small class="p-invalid p-error" v-if="v$.add.ttsText.$error">{{
                                v$.add.ttsText.$errors[0].$message
                                }}</small>

                        </div>
                        <div class="p-field p-col-12 p-md-4" v-if="add.tuneType.value == 1">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-12">
                                    <label for="audio">
                                        Upload Mp3
                                        <span class="p-invalid p-error">*</span>
                                    </label>
                                    <FileUpload v-model="add.tuneupload" mode="basic" ref="tuneuploadref"
                                        :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Tune" auto
                                        @select="handleDefaultTuneAddUpload" />
                                    <small class="p-invalid p-error" v-if="v$.add.tuneupload.$error">{{
                                        v$.add.tuneupload.$errors[0].$message
                                        }}</small>
                                    <small class="p-invalid p-error" v-if="tuneuploaderroradd">{{ tuneuploaderroradd
                                        }}</small>
                                </div>
                                <div class="p-field p-col-12 p-md-12" v-if="exitaddpreviewurl != null">{{
                                    exitaddpreviewurl.name
                                    }}</div>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label>Status <span class="p-invalid p-error"> *</span></label>
                            <div class="p-field-radiobutton">
                                <RadioButton id="status_active" name="option" v-bind:value="1" v-model="add.statustype"
                                    :checked="add.statustype == 1" />
                                <label for="status_active" class="p-mr-2">Active</label>
                                <RadioButton id="status_Inactive" name="option" v-bind:value="0"
                                    v-model="add.statustype" :checked="add.statustype == 0" />
                                <label for="status_Inactive" class="p-mr-2">Inactive</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addDefualtTunes" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add Default Tunes dialog end here -->
    <!-- edit Default Tunes dialog start here -->
    <Dialog v-model:visible="editTuneStatus" :style="{ width: '800px' }" header="Edit Default Tune" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="tuneCatagoryid">
                        Tune Catagory
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="tuneCatagoryid" v-model="edit.tuneCatagory" :options="tuneCatagoryList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.tuneCatagory.$error">{{
                        v$.edit.tuneCatagory.$errors[0].$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="tunetypeid">
                        Tune Type
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="tunetypeid" v-model="edit.tuneType" :options="tuneTypeList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.tuneType.$error">{{
                        v$.edit.tuneType.$errors[0].$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="aliasnameid">
                        Tune Alias name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="aliasnameid" v-model.trim="edit.aliasname" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !edit.aliasname }" />
                    <small class="p-invalid p-error" v-if="v$.edit.aliasname.$error">{{
                        v$.edit.aliasname.$errors[0].$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6" v-if="edit.tuneType.value == 2">
                    <label for="ttsTextid">
                        Tune Text
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="ttsTextid"  v-model.trim="edit.ttsText" required="true"
                        autofocus :class="{ 'p-invalid': submitted && !edit.ttsText }" />
                    <!-- <small class="p-invalid p-error" v-if="v$.edit.ttsText.$error">{{
                                v$.edit.ttsText.$errors[0].$message
                            }}</small> -->
                </div>

                <div class="p-field p-col-12 p-md-4" v-if="edit.tuneType.value == 1">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-12">
                            <label for="audio">
                                Upload Mp3
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <FileUpload v-model="edit.tuneupload" mode="basic" ref="tuneuploadeditref"
                                :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Tune" auto
                                @select="handleDefaultTuneEditUpload" />
                            <!-- <small class="p-invalid p-error" v-if="tuneuploaderror">{{ tuneuploaderror }}</small> -->
                        </div>
                        <div class="p-field p-col-12 p-md-12 p-text-center" v-if="tunesourceeditUrl != null">
                            <div class="p-text-center" v-if="exiteditpreviewurl == ''">
                                <audio controls controlsList="download">
                                    <source :src="tunesourceeditUrl" type="audio/mpeg" />
                                </audio>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-12" v-if="exiteditpreviewurl != null">{{
                            exiteditpreviewurl.name
                            }}</div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="status_active" name="option" v-bind:value="1" v-model="edit.statustype"
                            :checked="edit.statustype == 1" />
                        <label for="status_active" class="p-mr-2">Active</label>
                        <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="edit.statustype"
                            :checked="edit.statustype == 0" />
                        <label for="status_Inactive" class="p-mr-2">Inactive</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateDefaultTunes" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit Default Tunes dialog end here -->
    <!----Start of filter--------------------------------->
    <!-- <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="state">State</label>
                    <MultiSelect v-model="state_fks" :options="stateList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="loksabhaname">Lok Sabha</label>
                    <MultiSelect v-model="loksabha_fks" :options="defaultTuneList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(state_fks, loksabha_fks)" />
        </template>
    </Dialog> -->
    <!----End of filter--------------------------------->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import axios from 'axios';
export default {
    data() {
        return {
            v$: useValidate(),
            defaultTuneList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addDefaultTunesStatus: false,
            editTuneStatus: false,
            statustype: 1,
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            showFillFilter: false,
            isImportLoksabha: false,
            imagestorageimgpath: '',
            storage_path: '',
            tuneCatagoryList: [
                { label: 'Welcome Tune', value: 1 },
                { label: 'Hold Tune', value: 2 },
                { label: 'Non-Working Hours', value: 3 },
                { label: 'Missed Call Tune', value: 4 },
            ],
            tuneTypeList: [
                { label: 'MP3', value: 1 },
                { label: 'TTS', value: 2 },
            ],
            tuneuploadref: '',
            tuneuploaderror: '',
            tuneuploaderroradd: '',
            tuneuploadUrl: '',
            exiteditpreviewurl: '',
            exitaddpreviewurl: '',
            add: {
                tuneCatagory: '',
                tuneType: '',
                aliasname: '',
                ttsText: '',
                tuneupload: null,
            },
            edit: {
                tuneCatagory: '',
                tuneType: '',
                aliasname: '',
                ttsText: '',
                tuneupload: null,
            },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getAllDefaultTunes();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        this.imagestorageimgpath = this.storage_path;
        // this.fetchData();
    },
    validations() {
        return {
            add: {
                tuneType: { required: helpers.withMessage('Please select tune type', required) },
                tuneCatagory: { required: helpers.withMessage('Please select tune catagory', required) },
                aliasname: { required: helpers.withMessage('Please enter tune alias name', required) },
                ttsText: {
                    requiredIf: helpers.withMessage(
                        "Please select ttsText",
                        requiredIf(
                            this.add.tuneType.value == 2
                        )
                    ),
                },
                tuneupload: {
                    requiredIf: helpers.withMessage(
                        "Please select tune type",
                        requiredIf(
                            this.add.tuneType.value == 1
                        )
                    ),
                },
            },
            edit: {
                tuneType: { required: helpers.withMessage('Please select tune type', required) },
                tuneCatagory: { required: helpers.withMessage('Please select tune catagory', required) },
                aliasname: { required: helpers.withMessage('Please enter tune alias name', required) },
                // ttsText: {
                //     requiredIf: helpers.withMessage(
                //         "Please select ttsText",
                //         requiredIf(
                //             this.add.tuneType.value == 2
                //         )
                //     ),
                // },
                // tuneupload: {
                //     requiredIf: helpers.withMessage(
                //         "Please select tune type",
                //         requiredIf(
                //             this.add.tuneType.value == 1
                //         )
                //     ),
                // },
            },

        };
    },
    methods: {

        fetchData() {
            axios.post('/api/v1/u/testHTTPOnlyCookies')
                .then(response => {
                    console.log(response.data);
                    // Handle response data
                })
                .catch(error => {
                    console.error(error);
                    // Handle error
                });
        },


        onSubmit() {
            return;
        },
        getAllDefaultTunes(ev) {
            this.loading = true;
            this.ApiService.getAllDefaultTunes(ev).then((data) => {
                if (data.status == 200) {
                    this.defaultTuneList = data.result;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.defaultTuneList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAllDefaultTunes({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },
        handleDefaultTuneAddUpload() {
            var tuneupload = this.$refs.tuneuploadref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            if (!tuneupload) {
                this.tuneuploaderroradd = 'Please upload tune';
            } else if (!tuneupload.size > 2048) {
                this.tuneuploaderroradd = 'File size cannot exceed more than 2MB';
            } else if (!tuneupload.name.match(allowedExtensions)) {
                this.tuneuploaderroradd = 'Invalid file type';
            } else {
                // this.tuneupload = this.$refs.tuneupload.files[0];
                // this.tuneuploadUrl = { name: this.tuneupload.name, url: this.tuneupload.objectURL };
                this.tuneuploaderroradd = '';
                this.add.tuneupload = this.$refs.tuneuploadref.files[0];
                this.exitaddpreviewurl = this.add.tuneupload;
            }
        },
        handleDefaultTuneEditUpload() {
            var tuneupload = this.$refs.tuneuploadeditref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            if (!tuneupload) {
                this.tuneuploaderror = 'Please upload tune';
            } else if (!tuneupload.size > 2048) {
                this.tuneuploaderror = 'File size cannot exceed more than 2MB';
            } else if (!tuneupload.name.match(allowedExtensions)) {
                this.tuneuploaderror = 'Invalid file type';
            } else {
                // this.tuneupload = this.$refs.tuneupload.files[0];
                // this.tuneuploadUrl = { name: this.tuneupload.name, url: this.tuneupload.objectURL };
                this.tuneuploaderror = '';
                this.edit.tuneupload = this.$refs.tuneuploadeditref.files[0];
                this.exiteditpreviewurl = this.edit.tuneupload;
            }
        },
        addDefaultTunesDialogOPen() {
            this.submitted = false;
            this.addDefaultTunesStatus = true;
            this.add.statustype = 1;
            this.add.tuneupload = null;
            this.edit.tuneupload = null;
        },
        editDefaultTunesDialogOPen(e) {
            this.getAllDefaultTunes();
            this.editTuneStatus = true;
            this.row_id = e.mbg1;
            let tunecategory_id = this.tuneCatagoryList.filter(function (item) {
                return item.value == e.mbg3;
            });
            if (tunecategory_id.length > 0) {
                this.edit.tuneCatagory = tunecategory_id[0];
            }
            this.tunesourceeditUrl = 'https://storage.googleapis.com/' + this.imagestorageimgpath + '/default_tune/' + e.mbg4;
            let tuneType_id = this.tuneTypeList.filter(function (item) {
                return item.value == e.mbg2;
            });
            if (tuneType_id.length > 0) {
                this.edit.tuneType = tuneType_id[0];
            }
            this.edit.aliasname = e.mbg5;
            this.edit.statustype = e.mbg6;
        },
        submitForm() {
            this.addDefualtTunes();
        },
        addDefualtTunes() {
            // this.submitted = true;
            this.errormsg = '';
            this.submitted = true;
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append('mbg2', this.add.tuneType.value);
            formData.append('mbg3', this.add.tuneCatagory.value);

            if (this.add.tuneType.value == 1) {
                if (this.add.tuneupload != null) {
                    formData.append('mbg4', this.add.tuneupload);
                }
            }
            else {
                formData.append('mbg4', this.add.ttsText);
            }
            formData.append('mbg5', this.add.aliasname);
            formData.append('mbg6', this.add.statustype);

            if (!this.v$.add.$error) {
                this.showLoader = true;
                this.ApiService.createUpdateAllDefaultTunes(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.addDefaultTunesStatus = false;
                        this.add.tuneType = '';
                        this.add.tuneCatagory = '';
                        this.add.tuneupload = '';
                        this.add.aliasname = '';
                        this.add.statustype = '';
                        this.getAllDefaultTunes();
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.goToDashboard();
                        }, 4000);
                    } else {
                        this.addDefaultTunesStatus = true;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },
        updateDefaultTunes() {
            this.submitted = true;
            this.v$.edit.$validate();
            var formData = new FormData();
            formData.append('mbg1', this.row_id);
            formData.append('mbg2', this.edit.tuneType.value);
            formData.append('mbg3', this.edit.tuneCatagory.value);

            if (this.edit.tuneType.value == 1) {
                if (this.edit.tuneupload != null) {
                    formData.append('mbg4', this.edit.tuneupload);
                }
            }
            else {
                formData.append('mbg4', this.edit.ttsText);
            }
            formData.append('mbg5', this.edit.aliasname);
            formData.append('mbg6', this.edit.statustype);

            if (!this.v$.edit.$error) {
                this.showLoader = true;
                this.ApiService.createUpdateAllDefaultTunes(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;

                        this.editTuneStatus = false;
                        this.edit.tuneType = '';
                        this.edit.tuneCatagory = '';
                        this.edit.tuneupload = '';
                        this.edit.aliasname = '';
                        this.edit.statustype = '';
                        this.getAllDefaultTunes();
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.goToDashboard();
                        }, 4000);
                    } else {
                        this.editTuneStatus = true;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getloksabha();
        },
        getFilteredData(event_state, event_loksabha) {
            this.loading = true;
            this.state_fks = event_state;
            this.loksabha_fks = event_loksabha;
            this.getAllDefaultTunes({
                statefilter: this.state_fks,
                loksabhafilter: this.loksabha_fks
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.state_fks = "";
            this.loksabha_fks = '';
            this.getAllDefaultTunes();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
